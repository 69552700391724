import { Links, LiveReload, Meta, Scripts, ScrollRestoration, Outlet, Link, json, useMatches, useCatch } from 'remix';
import type { MetaFunction, LinksFunction, LoaderFunction } from 'remix';
import { Header, Footer, NotFound, Menu, MainMenu } from 'wally-design-system';
import designStyle from 'wally-design-system/lib/styles/index.css';
import { ModalProvider, NotificationProvider, CommandProvider, useModal, useCommand, useTheme } from '~/context';
import type { User } from './utils/session.server';
import { getUser } from './utils/session.server';
import i18next from 'i18next';
import { userNavigation } from '~/constants';
import en from './locales/en.json';
import es from './locales/es.json';
// import { I18nextProvider } from 'react-i18next';
// import { loadRootData, useRootData } from "./utils/useRootData";

export const meta: MetaFunction = () => ({ title: 'Wally' });

export const links: LinksFunction = () => ([
  { rel: 'stylesheet', href: designStyle },
  { rel: 'stylesheet', href: 'https://use.typekit.net/wdd6isw.css' },
]);

i18next.init({
  interpolation: { escapeValue: false },
  lng: 'en',
  resources: { en: { common: en }, es: { common: es } },
});

type LoaderData = { session: User | undefined };

export const loader: LoaderFunction = async ({ context, request }) => {
  return json<LoaderData>({ session: await getUser(request, context.sessionStorage) });
};

export function Layout() {
  const { openModal } = useModal(), { openCommand } = useCommand()
  const { session } = useMatches()[0].data as { session: User }, { user, hasPages } = session || {};
  const menuOptions = [{ text: 'Discover', to: '/discover', type: 'link', }, { text: 'Market', to: '/market', type: 'link', disabled: true }, { text: 'P2P', to: '/p2p', type: 'link', disabled: true }]
  
  return (
    <div className="flex flex-col h-screen">       
    <div>
      <Header
        session={!!session}
        user={user}
        connect={() => openModal({ type: 'connect_with', showBar: false, padding: 'large' })}
        openSearch={() => openCommand()}
        userNavigation={userNavigation({user: !!user, hasPages})}
        communityMenu={<Menu component={Link} items={menuOptions} />}
        menu={<MainMenu user={user} component={Link} items={userNavigation({user: !!user, hasPages})} showFeature={hasPages} />}
        component={Link}
      />
    </div>
    <div className="grow flex flex-col">
      <Outlet />
    </div>
    <Footer/>
    </div>
  );
}



export default function App() {
  // const { theme } = useTheme()
  return (
    <html lang="en" className={`dark bg-background`}>
      <head>
        <meta charSet="utf-8" />
        <meta name="viewport" content="width=device-width,initial-scale=1" />
        <script src="https://cdnjs.cloudflare.com/ajax/libs/stellar-sdk/10.1.0-beta.0/stellar-sdk.min.js" integrity="sha512-lujhwv2ioQeQ6B1Dsc+WTc8Fx6IXGM25e7lbYwG3vABdeMqz7RKbG2lX1zS81JA4KFRH4Ekv4ZXIxD1nSrTrJQ==" crossOrigin="anonymous" referrerPolicy="no-referrer"></script>
        <script src="https://cdnjs.cloudflare.com/ajax/libs/peerjs/2.0.0-beta.3/peerjs.min.js" integrity="sha512-/b9G70XUEYGTOv2bh4ZKwvysh6pIwvrfXOkuVIvmg5nwfuJZoSH0a4MD1rF92SmQtDz4W+e3MiX9niGw15kaPw==" crossOrigin="anonymous" referrerPolicy="no-referrer"></script>
        <script src="https://cdnjs.cloudflare.com/ajax/libs/bodymovin/5.9.4/lottie.min.js" integrity="sha512-ilxj730331yM7NbrJAICVJcRmPFErDqQhXJcn+PLbkXdE031JJbcK87Wt4VbAK+YY6/67L+N8p7KdzGoaRjsTg==" crossOrigin="anonymous" referrerPolicy="no-referrer"></script>
        <script src="https://cdnjs.cloudflare.com/ajax/libs/ipfs/0.63.3/index.min.js" integrity="sha512-CYTgEKZqJsINmWJ494eayZoE8EpyuSr8xvT21TK2OzJn/78YoZtGA1ouKSZwYFs8ewFZLG+NfBOn5FvdA76hBg==" crossOrigin="anonymous" referrerPolicy="no-referrer"></script>
        {/* <script src="https://cdnjs.cloudflare.com/ajax/libs/stellar-base/8.0.1/stellar-base.min.js" integrity="sha512-BWt0LfNUmOJzk29jevkinZ3Eyl2l/QgPVrGIOHFGKO30fyPX5x+gNgG16LdqwSG0sHaXO5E8Bjf635IRx4TCfw==" crossOrigin="anonymous" referrerPolicy="no-referrer"></script> */}
        {/* Prevent favicon fetch at root */}
        <link rel="icon" href="data:,"></link>
        <Meta />
        <Links />
      </head>
      <body className="min-h-screen bg-gradient-to-tr from-neutral-50 to-background max-h-full max-w-full">
        <NotificationProvider>
          <CommandProvider>
            <ModalProvider>
              <Layout />
            </ModalProvider>
          </CommandProvider>
        </NotificationProvider>
        <ScrollRestoration />
        <Scripts />
        {process.env.NODE_ENV === 'development' && <LiveReload />}
      </body>
    </html>
  );
}

export function CatchBoundary() {
  const { data, status } = useCatch();
  return (
    <html lang="en" className="dark bg-background">
      <head>
        <title>{data} {status}</title>
        <Meta />
        <Links />
      </head>
      <body className="min-h-screen bg-neutral-50 max-h-full max-w-full">
        <NotFound data={data} status={status} />
        <Scripts />
      </body>
    </html>
  );
}