
type itemsNavigationProps = {
    text: string;
    type: 'button' | 'link' | 'action';
    icon: string;
    action?: () => void;
    to?: string;
  }[];


export const userNavigation = ({user, hasPages}: {user: boolean, hasPages: boolean}): itemsNavigationProps => {
    const nav: itemsNavigationProps = [];
    nav.push(
      // { text: 'Manifesto', to: '/manifesto', type: 'link', icon: '' }
      )
    if (hasPages) {
      nav.push(
      {
        text: 'Dashboard',
        to: '/dashboard',
        type: 'link',
        icon: 'dashboard'
      }
      )
    }
    if (user) {
      nav.push(
      {
        text: 'Agenda',
        to: '/agenda',
        type: 'link',
        icon: 'calendar'
      },
      {
        text: 'Appointments',
        to: '/appointments',
        type: 'link',
        icon: 'booking'
      },
      // {
      //   text: 'Contracts',
      //   to: '/contracts',
      //   type: 'link',
      //   icon: 'dots'
      // },
      // {
      //   text: 'Mint',
      //   to: '/mint',
      //   type: 'link',
      //   icon: 'dots'
      // }
      )
    }
    if (hasPages) {
      nav.push({
        text: 'Experiences',
        to: '/experiences',
        type: 'link',
        icon: 'blackhole'
      },
      {
        text: 'Profile',
        to: '/profile',
        type: 'link',
        icon: 'user'
      },
      {
        text: 'Bookings',
        to: '/bookings',
        type: 'link',
        icon: 'inbox'
      }
      )
    }

    if(!hasPages) {
      nav.push({ text: 'Settings', to: '/settings', type: 'link', icon: 'cogwheel' })
    }
    nav.push(
    // { text: 'Wallets', to: '/settings/wallets', type: 'link', icon: 'wallet' },
    { text: 'Disconnect', type: 'action', to: '/logout', icon: 'power' })
    return nav
  }